<template>
  <div>
    <b-modal
      id="widget-expanded-view"
      v-model="modalShow"
      size="lg"
      :content-class="`widget-expanded-modal ${content.widgetType}`"
      :body-class="{
        'linked-form': content && content.integrationWidgetContent && !content.integrationWidgetContent.isLinked,
      }"
      :title="content.widgetName"
      hide-footer
      centered
      no-stacking
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      title-sr-only
    >
      <template #modal-header>
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div class="widget-logo-wraper">
            <div class="widget-logo" :style="{ backgroundColor: content.widgetColor }">
              <img id="widget-logo-blob-uri" :src="content.widgetLogoBlobURI" />
            </div>
            <div class="widget-title px-3">
              <h2 class="font-17 mb-0" id="widget-expanded-view___BV_modal_title_">{{ content.widgetName }}</h2>
            </div>
          </div>
          <div class="modal-right-items">
            <b-button size="sm" id="widget-expanded-settings-btn" variant="outline-dark" class="px-3 mr-4" @click="navigateWidgetSettings">
              <b-icon-gear :aria-label="null" aria-hidden="true" class="mr-0 mr-sm-2"></b-icon-gear
              ><span class="d-none d-sm-inline">{{ translateLanguage('Btn_Widget_Settings') }}</span></b-button
            >

            <a href="javascript:void(0)" role="button" id="widget-expanded-close-btn" @click="closeWidgetExpanedModal"
              ><img src="../../../assets/img/close.png" alt="close"
            /></a>
          </div>
        </div>
      </template>
      <div class="d-block px-3">
        <div>
          <div
            v-if="content.widgetCategoryId !== 2 && !showWidgetExpanedSecondModal.show"
            class="theme-font-regular font-16 user-content-expanded"
            v-html="content.expandedVwTxt"
          ></div>
          <template v-if="content.isAddressMissing">
            <p class="font-16 address-missing-msg">
              Please add
              <router-link class="theme-font-bold" :to="`/${selectedLanguage}/account/property`">your address </router-link>
              to see more information on this widget.
            </p>
          </template>
        </div>
        <WidgetCategories v-if="content.data" :widgetDetails="content" :mode="mode" :isExpandedView="true" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BIconGear } from 'bootstrap-vue'
export default {
  name: 'ContentWidgetExpandedViewModal',
  props: ['content', 'mode'],
  components: {
    BIconGear,
    WidgetCategories: () => import('./WidgetCategories.vue'),
  },
  data() {
    return {
      modalShow: true,
    }
  },
  computed: {
    ...mapState({
      selectedLanguage: (state) => state.common.selectedLanguage,
      showWidgetExpanedSecondModal: (state) => state.shared.showWidgetExpanedSecondModal,
    }),
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.resetShowWidgetExpanedSecondModal()
    })
  },
  methods: {
    async navigateWidgetSettings() {
      if (this.mode === 'Preview') {
        return
      }
      this.$bvModal.hide('widget-expanded-view')
      await this.$router.push({ name: 'Widget settings', params: { culture: this.selectedLanguage, widgetId: this.content.widgetId } }).catch(() => {})
      setTimeout(() => {
        this.$store.commit('shared/setDashboardSettingsNavigation', true)
      }, 2000)
    },
    closeWidgetExpanedModal() {
      if (this.showWidgetExpanedSecondModal.widgetExpandedClick) {
        this.resetShowWidgetExpanedSecondModal()
      } else {
        this.$bvModal.hide('widget-expanded-view')
      }
    },
    resetShowWidgetExpanedSecondModal() {
      this.$store.commit('shared/setShowWidgetExpanedSecondModal', {
        show: false,
        widgetExpandedClick: false,
      })
      this.$store.commit('shared/setFormDetails', {
        loading: false,
        showFormDetails: false,
        data: null,
        error: null,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../../variables.scss';
.modal-right-items {
  white-space: nowrap;
}
:deep() {
  .widget-expanded-modal {
    border-radius: 0.5rem;

    .modal-header {
      padding: 0 1rem 0 0;
      align-items: center;
      border-bottom: none;
    }

    .modal-body {
      margin-right: 0.5rem;
      max-height: 500px;
      overflow-y: auto;
      scrollbar-width: thin;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .linked-form {
      overflow-y: visible;
    }

    .modal-body::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    .modal-body::-webkit-scrollbar-track {
      background: #ececec;
      border-radius: 25rem;
    }

    .modal-body::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 25rem;
    }

    .custom-scroll {
      overflow-y: auto;
      scrollbar-width: thin;
    }

    .custom-scroll::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    .custom-scroll::-webkit-scrollbar-track {
      background: #ececec;
      border-radius: 25rem;
    }

    .custom-scroll::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 25rem;
    }
  }
}
.widget-logo-wraper {
  display: flex;

  .widget-logo {
    width: 64px;
    min-width: 64px;
    align-items: center;
    font-weight: 600;
    height: 60px;
    display: grid;
    justify-content: center;
    border-radius: 5px 0px;

    svg,
    img {
      width: 30px;
      height: 30px;
    }
  }

  .widget-title {
    word-break: break-word;
    display: flex;
    align-items: center;
    font-family: $THEMEFONTBOLD;
    font-size: 17px;
  }
}
</style>
